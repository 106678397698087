<template>
  <div class="modal modal-center" id="newBranch">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Edit Branch
        </h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-3 mb-3" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-0" v-else>
          <div class="form-group full-width">
            <label for="fieldName">
              Name
            </label>
            <input type="text" class="form-control" id="fieldName" v-model="formData.name">
          </div>
          <div class="form-group full-width mt-3">
            <label for="fieldManager">
              Manager
            </label>
            <multiselect v-model="formData.manager"
                         placeholder="Select Manager"
                         class="full-width"
                         id="fieldManager"
                         :options="managers"
                         :searchable="true"
                         :close-on-select="true"
                         :allow-empty="false"
                         :show-labels="false">
            </multiselect>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <button class="btn bg-green" @click="saveBranch">
          <span v-if="ui.saving === true"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-if="ui.saving === false" >Save</span>
        </button>
        <button class="btn btn-cancel ms-2" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'EditBranchModal',
  components: { Multiselect },
  data() {
    return {
      formData: {
        name: '',
        manager: ''
      },
      ui: {
        saving: false,
        loading: false
      },
      managers: []
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    },
    saveBranch() {
      this.ui.saving = true;

      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    },
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
#newBranch {
  .modal-block {
    height: fit-content;
    margin: 50px auto;
    .modal-body {
      padding: 100px 30px 20px 30px;
    }
    .modal-footer {
      bottom: unset;
    }
  }
}
</style>
