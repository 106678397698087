<template>
  <div class="modal modal-center" id="newBranch">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Create new Branch
        </h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="form-group full-width">
            <label for="fieldName">
              Name
            </label>
            <input type="text" class="form-control" id="fieldName" v-model="formData.name">
          </div>
          <div class="form-group full-width mt-3">
            <label for="fieldManager">
              Manager
            </label>
            <multiselect v-model="formData.manager"
                         placeholder="Select Manager"
                         class="full-width"
                         id="fieldManager"
                         :options="managers"
                         :searchable="true"
                         :close-on-select="true"
                         :allow-empty="false"
                         :show-labels="false">
            </multiselect>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <base-button title="Save" action="secondary-default" @click-btn="saveBranch" :loading="ui.saving" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: 'NewBranchModal',
  components: { Multiselect, BaseButton },
  data() {
    return {
      formData: {
        name: '',
        manager: ''
      },
      ui: {
        saving: false,
      },
      managers: []
    }
  },
  methods: {
    saveBranch() {
      this.ui.saving = true;

      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
#newBranch {
  .modal-block {
    height: fit-content;
    margin: 50px auto;
    .modal-body {
      padding: 100px 30px 20px 30px;
    }
    .modal-footer {
      bottom: unset;
    }
  }
}
</style>
