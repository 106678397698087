<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Branches
        </h3>
      </div>
      <base-button title="Add new branch" action="primary" @click-btn="modals.newBranch = true" />
    </div>
    <div class="page-content p-normal">
      <div class="row">
        <div class="col-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input v-model="query" type="text" placeholder="Search">
          </div>
        </div>
      </div>
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Manager</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Newport</td>
              <td>Arthur Greenbaum</td>
              <td>
                <div class="d-flex">
                  <button class="btn btn-outline-dark" @click="editBranch('1')">
                    Edit
                  </button>
                  <button class="btn btn-outline-dark ms-2">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <new-branch-modal v-if="modals.newBranch === true"
                        @close="modals.newBranch = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <edit-branch-modal  v-if="modals.editBranch === true"
                          @close="modals.editBranch = false" />
    </transition>

  </div>
</template>

<script>
import NewBranchModal from "./modals/NewBranch";
import EditBranchModal from "./modals/EditBranch";
import BaseButton from '../../components/BaseButton.vue';
export default {
  name: 'Branch',
  components: {EditBranchModal, NewBranchModal, BaseButton },
  data() {
    return {
      modals: {
        newBranch: false,
        editBranch: false
      },
      branchId: null
    }
  },
  methods: {
    editBranch(id) {
      this.branchId = id;
      this.modals.editBranch = true;
    }

  }
}
</script>

<style lang="scss" scoped>
table {
  tbody {
    tr {
      vertical-align: middle;
    }
  }
}
</style>
